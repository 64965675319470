<template>
  <!--
    The view for the IPScanner-component
  -->
  <IpScanner
    :installation-id="installationId"
    :lane-number="laneNumber"
  />
</template>

<script>
export default {
  name: "IpScannerView",
  components: {
    IpScanner: () => import('@/components/Device/IpScanner.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('ipScanner')
    }
  }
}
</script>
